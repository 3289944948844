// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Canvas, } from '@react-three/fiber'
import { Effects, } from '@react-three/drei'
import { Suspense, useRef } from 'react'
import ArrowSvg from '@components/common/ArrowSvg'
import PhenomenalText from './PhenomenalText'
import Geo from './Geo'
import state from './state'

export default function Phenomenal() {
  const scrollArea = useRef()

  return (
    <div className='phenomenal-cursor -mb-300px relative flex flex-row-reverse w-65rem h-50rem justify-between items-center mx-auto gap-2rem <lg:-mb-200px <lg:w-54rem <md:w-44rem <sm:flex-col <sm:w-90% <sm:max-w-500px <sm:-mb-550px' >
      <PhenomenalText />
      <ArrowSvg className={'SVG w-5rem h-4rem <lg:w-4rem <lg:h-3rem <md:w-2.5rem absolute left-43% transform rotate-290  -scale-y-100 -scale-x-100 top-32rem <lg:left-40% <lg:top-30rem <md:top-28rem <md:left-30% <sm:scale-y-100 <sm:top-10rem <sm:left-5 z-1 <md:hidden <sm:rotate-316'} />
        <Canvas
        className='overflow-hidden mt-0 -mr-9rem right-6rem <lg:!w-150% <lg:scale-90 <md:scale-80 <md:!w-240% <sm:scale-100 <md:hidden <sm:right-0 <sm:mr-0 <sm:!w-100% z-0'
        shadows
        raycaster={{ enabled: false }}
        dpr={[1, 2]}
        camera={{ position: [0, 0, 10], far: 1000, fov: 25 }}
        gl={{ powerPreference: 'high-performance', alpha: false, antialias: false, stencil: false, depth: false }}
        onCreated={({ gl }) => gl.setClearColor('#ffffff')}
      >
        <pointLight position={[-10, -10, -10]} intensity={0.4} />
        <ambientLight intensity={0.4} />
        <spotLight
          castShadow
          angle={0.3}
          penumbra={1}
          position={[0, 10, 20]}
          intensity={5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
        />
        <Suspense fallback={null}>
          <Geo />
        </Suspense>
        <Effects />
      </Canvas>
      <div
        className="absolute left-0 top-0 overflow-auto w-50vw h-100vh <sm:hidden"
        ref={scrollArea}
        onPointerMove={e => (state.mouse = [(e.clientX / window.innerWidth) * 2 - 1, (e.clientY / window.innerHeight) * 2 - 1])}>
      </div>
    </div>
  )
}
