import Button from '@components/common/Button'
import MaskedTitle from '@components/common/MaskedTitle'

export default function PhenomenalText() {
  return (
    <div className='relative w-50rem <sm:w-full'>
      <div
        className="w-45rem absolute z-1 top-50% transform -translate-y-50% left-0 flex-col justify-start items-start gap-16px flex <lg:w-33rem <md:-top-2rem <md:w-full <md:items-center <sm:w-26rem <sm:items-center <sm:justify-center <sm:left-50% <sm:-translate-x-50% <sm:top-2rem"
      >
        <MaskedTitle
          title=" Your product will be Phenomenal with Pheno"
          titleClassName="text-40px font-700 leading-48px text-hex-0B2864 <lg:text-26px <lg:leading-30px <md:!text-26px <md:text-center <sm:!text-28px font-Montserrat "
          className='w-95% z-1 <md:w-91%'
          logoClassName='!-top-18px at-sm:left-3 at-sm:!-top-8 <sm:!left-35px'
        />

        <p
          className="text-16px font-500 leading-24px text-hex-445069 w-80% <lg:text-14px <lg:leading-21px <md:w-90% <md:max-w-512px <md:mx-auto <md:text-left <sm:max-w-368px <sm:text-center font-Montserrat"
        >
          Partner with Pheno and witness the transformation of your product into a
          phenomenal digital solution contact us to bring your vision to life.
        </p>
        <Button title='Work with us' link='/contact-us' titleClassName='!text-18px <md:!text-16px' buttonClassName='mx-auto <sm:mt-6' />

      </div>
    </div>
  )
}
